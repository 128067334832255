import { Component, EventEmitter, inject, Injector, Input, OnDestroy, OnInit, Output, Signal } from '@angular/core';

import { cloneDeep, each, find as _find, findIndex as _findIndex } from 'lodash-es';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, shareReplay, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';

import { QuestionService } from '../../question/question.service';
import { AppState, getState } from '../../store/models/app.state';
import { PharDragService } from '../../shared/drag.service';
import { ElementsEnum, FormElementsEnum } from '../form-elements.enum';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';

import { globalLoading, setDragged, setDraggedHelper } from '../../store/actions/ui.actions';
import { formAddQuestion, formUpdateQuestion, updateForm } from '../store/form.actions';
import { populateCurrentControl, resetCurrentControl } from '../../store/actions/control.actions';
import { QuestionModel } from '../../question/question.model';
import { ControlModel } from '../../question/control.model';
import {
  createQuestion,
  dispatchedCreateUpdateQuestion,
  pendingCreateUpdateQuestion,
  populateCurrentQuestion,
  resetCurrentQuestion,
  successCreateUpdateQuestion,
  updateQuestion,
  updateQuestionField,
} from '../../question/store/question.actions';
import { UtilsService } from '../../core/utils.service';
import { AppConfig } from '../../core/config/app.config';
import { FormModel, IFormPage, FormTypeEnum } from '../form.model';
import { DatasetModel } from '../../dataset/dataset.model';
import { updateDataset } from '../../dataset/store/dataset.actions';
import { Actions, ofType } from '@ngrx/effects';
import {
  QUESTION_INITIAL_STATE,
  selectCurrentQuestion,
  selectCurrentQuestionPendingChanges,
} from '../../question/store/question.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { selectCurrentFormStateForm, selectCurrentFormStateFormPopulatedQuestions } from '../store/form.state';
import { getNavigatorUsers } from '../../user/store/user.actions';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'phar-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
})
export class FormPageComponent implements OnInit, OnDestroy {
  @Input() page: IFormPage;
  @Input() form: FormModel;
  @Input() builderMode = true;
  @Input() allowSelectEditMode = true;
  @Input() isCommentsVisible = true;
  @Input() isVerticalLayout = false;
  @Output() afterUpdate = new EventEmitter<any>();
  @Output() editModeChanged = new EventEmitter<FormElementsEnum>();
  @Output() formUpdated = new EventEmitter<string>();
  @Output() requestPageChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() requestEditQuestion: EventEmitter<QuestionModel> = new EventEmitter<QuestionModel>();
  @Output() requestResetRightPanel: EventEmitter<any> = new EventEmitter<any>();

  currentDataset: DatasetModel;
  FormElementsEnum = FormElementsEnum;
  questionDragStart: Observable<boolean>;
  formQuestions$: Observable<any>;
  parentDataField = '';
  subscription: Subscription = new Subscription();
  selectedQuestion$: Observable<QuestionModel>;
  hasPendingChanges$: Observable<boolean>;
  dragDataTemp: any;
  dropNodeTemp: any;
  canDropControl = true;
  canDropHeader = true;
  canDropFooter = true;
  private currentForm: Signal<FormModel>;
  private activeDropzoneClass = 'form-page_drop-zone--highlight--drag-over';
  private QUESTION_EDITABLE_FIELDS = [
    'title',
    'info',
    'isRepeatable',
    'label',
    'allowedIterations',
    'iterationOnNewPage',
  ];
  private hasMissingDataFields = false;
  private readonly injector = inject(Injector);
  private readonly destroy$ = new Subject<null>();

  constructor(
    private actions$: Actions,
    private appConfig: AppConfig,
    public questionService: QuestionService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private dragService: PharDragService,
    private confirmDialog: PharConfirmDialogService,
    public dialog: MatDialog,
    private utilsService: UtilsService,
  ) {}

  ngOnInit() {
    this.questionDragStart = this.store
      .select(store => store.ui.dragged)
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          const dragData = this.dragService.dragData;
          const isInGroup = dragData?.control?.inGroup;
          const isHeader = dragData?.control?.controlType === FormElementsEnum.Header;
          const isFooter = dragData?.control?.controlType === FormElementsEnum.Footer;

          this.canDropControl = !isInGroup && !isHeader && !isFooter;
          this.canDropHeader = isHeader && !getState(this.store).form.current.form.settings.showHeader;
          this.canDropFooter = isFooter && !getState(this.store).form.current.form.settings.showFooter;
        }),
        takeUntil(this.destroy$),
        shareReplay(1),
      );
    this.formQuestions$ = this.store.select(selectCurrentFormStateFormPopulatedQuestions).pipe(distinctUntilChanged());
    this.currentDataset = getState(this.store).dataset.current.dataset;
    this.hasPendingChanges$ = this.store.select(selectCurrentQuestionPendingChanges);
    this.selectedQuestion$ = this.store.select(selectCurrentQuestion);
    this.currentForm = toSignal(this.store.select(selectCurrentFormStateForm), { injector: this.injector });

    this.actions$
      .pipe(
        ofType<ReturnType<typeof updateQuestionField>>(updateQuestionField),
        filter(state => this.QUESTION_EDITABLE_FIELDS.includes(state.field)),
        withLatestFrom(this.store.select(selectCurrentQuestion), this.formQuestions$),
        map(([field, currentQuestion, formQuestionsPopulated]) => {
          if (!formQuestionsPopulated[currentQuestion.id]) {
            return null;
          }

          const updatedFormQuestion = {
            ...formQuestionsPopulated[currentQuestion.id],
            [field.field]: field.value,
          };

          return updatedFormQuestion;
        }),
        filter(
          (updatedFormQuestion: QuestionModel | null): updatedFormQuestion is QuestionModel =>
            updatedFormQuestion !== null,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(updatedFormQuestion => {
        this.store.dispatch(formUpdateQuestion({ question: updatedFormQuestion }));
      });

    this.actions$
      .pipe(
        ofType<ReturnType<typeof successCreateUpdateQuestion>>(successCreateUpdateQuestion),
        takeUntil(this.destroy$),
      )
      .subscribe(createdQuestion => {
        // If there is no payload, we are not in the automatic question creation.
        if (!createdQuestion.isAutoCreate) {
          return;
        }

        const newQuestion = createdQuestion.response;

        const dragData = {
          question: newQuestion,
          reorder: false,
        };

        // Simulate drag of the control
        this.dragService.setDragData(dragData);
        this.onDrop(null, { question: newQuestion });
      });

    this.store.dispatch(getNavigatorUsers());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onDragStart(event, question): void {
    event.stopImmediatePropagation();
    const dragData = {
      question,
      reorder: true,
    };
    this.dragService.setDragData(dragData);
    this.store.dispatch(setDragged({ dragged: true }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: dragData }));
  }

  onDragEnd($event): void {
    $event?.preventDefault();
    $event?.stopImmediatePropagation();
    this.store.dispatch(setDragged({ dragged: false }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: {} }));
  }

  allowDrop(event, element: 'control' | 'header' | 'footer' = 'control'): void {
    if (!this.canDropElement(element)) {
      return;
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  onDrop(event, dropNode: any = false): void {
    if (!this.canDropControl) {
      return;
    }
    const dragData = this.dragService.dragData;
    // Drop starts here
    this.questionService.setQuestionAutoCreateMode(true);

    if (event) {
      event?.stopImmediatePropagation();
      event?.preventDefault();
      this.dragOverNewLine(event, 'end');
    }

    if (dragData.control) {
      this.dragDataTemp = dragData;
      this.dropNodeTemp = dropNode;
    }

    if (dragData.reorder) {
      this.reorderElements(dragData, dropNode);
    } else {
      this.addElementOndrop(dragData, dropNode);
    }

    this.afterUpdate.emit({ type: 'dropElement', data: this.page });

    if (dragData.reorder) {
      this.updateQuestionAfterControlRendered();
    }
  }

  dragOverNewLine(
    $event: DragEvent,
    action: 'start' | 'end' = 'start',
    element: 'control' | 'header' | 'footer' = 'control',
  ) {
    if (!this.canDropElement(element)) {
      return;
    }
    switch (action) {
      case 'start':
        ($event.target as HTMLElement).classList.add(this.activeDropzoneClass);
        break;
      default:
        ($event.target as HTMLElement).classList.remove(this.activeDropzoneClass);
    }
  }

  canDropElement(element: 'control' | 'header' | 'footer'): boolean {
    return (
      (element === 'control' && this.canDropControl) ||
      (element === 'header' && this.canDropHeader) ||
      (element === 'footer' && this.canDropFooter)
    );
  }

  onDropHeaderFooter(event: DragEvent, element: 'header' | 'footer'): void {
    if (event) {
      event?.stopImmediatePropagation();
      event?.preventDefault();
      this.dragOverNewLine(event, 'end');
    }
    const form = this.currentForm();
    const formSettings = cloneDeep(form.settings);
    let editMode: FormElementsEnum;

    if (element === 'header') {
      formSettings.showHeader = true;
      editMode = FormElementsEnum.Header;
    }

    if (element === 'footer') {
      formSettings.showFooter = true;
      editMode = FormElementsEnum.Footer;
    }

    this.store.dispatch(
      updateForm({
        form: {
          ...form,
          settings: formSettings,
        },
      }),
    );

    this.setEditMode({ type: editMode });
  }

  reorderElements(dragData, dropNode): void {
    this.store.dispatch(setDragged({ dragged: false }));
    if (
      dropNode &&
      dropNode.id &&
      dropNode.id === dragData.question.id &&
      (dragData.question.controlType !== 'Textblock' || dragData.question.controlType !== 'Grid')
    ) {
      return;
    }

    const element = dragData.question;
    this.removeElement(element, 'removeElementForReorder');

    if (!dropNode) {
      this.page.questions.push(element);
    } else {
      const index = _findIndex(this.page.questions, question => question['questionID'] === dropNode.questionID);
      this.page.questions = [
        ...[...this.page.questions.slice(0, index)],
        element,
        ...[...this.page.questions.slice(index)],
      ];
    }

    this.page.questions = this.page.questions.map((q, index) => ({ ...q, order: index }));
    this.formUpdated.emit();
  }

  autoCreateQuestionOnControlDrop(isGroup = false) {
    const form = getState(this.store).form.current.form;
    const datasetId: number = form.datasetId;
    const userIdCreated: number = form.userIdCreated;
    const formId: number = form.id;

    this.store.dispatch(resetCurrentQuestion());
    this.store.dispatch(resetCurrentControl());
    const { order }: IFormPage = this.currentForm().body.pages.find(page => page.page_uuid === this.page.page_uuid);
    const newQuestion: QuestionModel = {
      ...QUESTION_INITIAL_STATE,
      title: 'Question' + (isGroup ? ' group' : ''),
      label: 'Question' + (isGroup ? ' group' : ''),
      datasetId,
      formId,
      userIdCreated,
      pageIndex: order,
      isGroup,
      questionPageUuid: this.page.page_uuid,
      questionGroupGuid: this.utilsService.generateUUID(),
    };

    this.setModel();

    const datasetData = getState(this.store).dataset.current.dataset;
    this.utilsService.dispatchActions(this.store, [
      globalLoading(true),
      pendingCreateUpdateQuestion({ pending: true }),
      dispatchedCreateUpdateQuestion({ dispatched: true }),
      createQuestion({ question: newQuestion, isAutoCreate: true }),
      updateDataset({ dataset: datasetData, success: false }),
    ]);
  }

  saveQuestion() {
    const controls = getState(this.store).question.current.question.controls;
    this.removeDropZones(controls);
    this.setModel();

    const question = getState(this.store).question.current.question;

    let datasetId: number;
    let formId: number;

    this.store
      .select(store => store.form.current.form)
      .pipe(takeUntil(this.destroy$))
      .subscribe((form: FormModel) => {
        datasetId = form.datasetId;
        formId = form.id;
      });

    question.datasetId = datasetId;
    question.formId = formId;

    if (question) {
      const datasetData = getState(this.store).dataset.current.dataset;
      this.utilsService.dispatchActions(this.store, [
        pendingCreateUpdateQuestion({ pending: true }),
        dispatchedCreateUpdateQuestion({ dispatched: true }),
        updateQuestion({ question: question }),
        updateDataset({ dataset: datasetData, success: false }),
      ]);
    }
  }

  // missingDataFieldsError(type = 'add'): void {
  //   this.hasMissingDataFields = false;
  //   this.store.dispatch(
  //     errorPopup({
  //       error: `Please add Data Field to all controls before ${type} question and Repeatable Groups`,
  //     }),
  //   );
  // }

  removeDropZones(rawControls): void {
    const controls = this.questionService.removeDropZonesDeep(rawControls);
    this.store.dispatch(updateQuestionField({ field: 'controls', value: controls }));
  }

  updateQuestionAfterControlRendered() {
    this.store
      .select(state => state.question.current.question)
      .pipe(take(1))
      .subscribe(question => {
        if (this.questionService.isQuestionAutoCreateMode()) {
          this.store.dispatch(globalLoading(true));
          this.store.dispatch(updateQuestion({ question: question, isAutoCreate: true }));
        }
      });
  }

  editElement(element): void {
    if (element.controlType === 'Grid') {
      const index = _findIndex(this.page.questions, question => question['gridID'] === element.gridID);
      this.page.questions[index] = element;
      this.store.dispatch(formUpdateQuestion({ question: element }));
    } else if (element.controlType === 'PieChart') {
      const index = _findIndex(this.page.questions, question => question['gridID'] === element.gridID);
      this.page.questions[index] = element;
      this.store.dispatch(formUpdateQuestion({ question: element }));
    } else if (element.controlType === 'Textblock') {
      const index = _findIndex(this.page.questions, question => question['questionID'] === element.questionID);
      this.page.questions[index] = element;
      this.store.dispatch(formUpdateQuestion({ question: element }));
    } else {
      const index = _findIndex(this.page.questions, question => question['questionID'] === element.questionID);
      this.page.questions[index] = element;
      if (!element.controlType) {
        this.store.dispatch(formUpdateQuestion({ question: element }));
      }
    }
  }

  remove(element): void {
    this.confirmDialog
      .openConfirmDialog('Do you want to remove the component from the page?')
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.removeElement(element, 'removeElement');
        }
      });
  }

  removeElement(element, eventType): void {
    // if (element.controlType === 'Grid') {
    //   const index = _findIndex(this.page.questions, question => question['gridID'] === element.gridID);
    //   this.page.questions.splice(index, 1);
    //   this.afterUpdate.emit({ type: eventType, data: this.page, element });
    // } else if (element.controlType === 'PieChart') {
    //   const index = _findIndex(this.page.questions, question => question['gridID'] === element.gridID);
    //   this.page.questions.splice(index, 1);
    //   this.afterUpdate.emit({ type: eventType, data: this.page, element });
    // } else {
    //const index = _findIndex(this.page.questions, question => question['questionID'] === element.questionID);
    const index = _findIndex(this.page.questions, question => question['id'] === element.id);
    this.page.questions.splice(index, 1);
    this.afterUpdate.emit({ type: eventType, data: this.page, element });
    //}
  }

  setEditMode(data: {
    control?: ControlModel;
    question?: QuestionModel;
    type?: FormElementsEnum;
    parentDataField?: string;
  }): void {
    if (!data) {
      return;
    }

    this.store.dispatch(resetCurrentControl());
    if (data?.control) {
      this.parentDataField = data.parentDataField;
      setTimeout(() => {
        this.store.dispatch(populateCurrentControl({ control: data.control }));
      }, 0);
    }

    if (data.question) {
      this.store.dispatch(populateCurrentQuestion({ question: data.question }));
    }

    const editMode = data?.type || ('title' as FormElementsEnum);
    this.editModeChanged.emit(editMode);
  }

  handleRequestPageChange(pageId: string): void {
    this.requestPageChange.emit(pageId);
  }

  handleRequestEditQuestion(question: QuestionModel): void {
    this.requestEditQuestion.emit(question);
  }

  setModel(
    controls = getState(this.store).question.current.question.controls,
    datasetSchema = getState(this.store).dataset.current.dataset.datasetSchema,
    isDeep = false,
  ): any {
    const model = {};
    each(controls, item => {
      //TODO: Check why this condition does not work.
      if (item.controlType in ElementsEnum || item.controlType === 'dropzone') {
        return;
      }
      if (item.bindDataField) {
        if (item.controlType === this.FormElementsEnum.RepeatableGroup) {
          model[item.bindDataField] = [];
          model[item.bindDataField][0] = this.setModel(item.controls, datasetSchema[item.bindDataField][0], true);
        } else {
          if (
            (item.controlType === this.FormElementsEnum.ImageUpload ||
              item.controlType === this.FormElementsEnum.FileUpload) &&
            !item.bindDataField.includes('.' + this.appConfig.config.fileFieldPrefix)
          ) {
            // detect when bindDataField is FILE and append needed fileFieldPrefix to be recognized by the datasetSchema;
            const modifiedBindDataFieldName = this.utilsService.appendFilePrefix(item.bindDataField);
            model[modifiedBindDataFieldName] = datasetSchema[modifiedBindDataFieldName];
          } else {
            model[item.bindDataField] = datasetSchema[item.bindDataField];
          }
        }
      } else {
        if (
          item.controlType === this.FormElementsEnum.CheckBox ||
          item.controlType === this.FormElementsEnum.MultiSelectQuantity ||
          item.controlType === this.FormElementsEnum.MultiSelect
        ) {
          this.hasMissingDataFields = item.values.some(
            value => value.value === undefined || value.value === null || value.value === '',
          );
          item.values.forEach((value: { value: string; label: string }) => {
            model[value.value] = datasetSchema[value.value];
          });
        } else if (item.controlType === this.FormElementsEnum.DataTable) {
          if (this.filterEmptyDataFields(item.controls).length) {
            this.hasMissingDataFields = true;
          }
        } else {
          this.hasMissingDataFields = true;
        }
      }
    });

    if (isDeep) {
      return model;
    } else {
      this.store.dispatch(updateQuestionField({ field: 'model', value: model }));
      return;
    }
  }

  filterDataFields(ctrls): any[] {
    return ctrls.filter(
      c =>
        !(c.controlType in ElementsEnum) &&
        (c.bindDataField === undefined || c.bindDataField === null || c.bindDataField === ''),
    );
  }

  filterEmptyDataFields(ctrls: ControlModel[]): any[] {
    let filtered = this.filterDataFields(ctrls);
    filtered = filtered.filter((c: ControlModel) => {
      if (c.controlType === FormElementsEnum.CheckBox) {
        if (!c.values.length) {
          return true;
        } else {
          return Boolean(c.values.filter(e => this.isFieldEmpty(e, 'value')).length);
        }
      } else {
        return true;
      }
    });
    return filtered;
  }

  isFieldEmpty(element, field = 'bindDataField'): boolean {
    return element[field] === undefined || element[field] === null || element[field] === '';
  }

  private addElementOndrop(dragData, dropNode): void {
    if (dragData.question && _find(this.page.questions, question => question['id'] === dragData.question.id)) {
      this.snackBar.open(`This question is already on page.`, 'Close', {
        duration: 2000,
        verticalPosition: 'top',
      });
      return;
    }

    const question = dragData.question;
    if (!question) {
      const control = dragData.control;

      if (this.form.type === FormTypeEnum.DataCapture && this.page.questions.length >= 1) {
        this.confirmDialog.openConfirmDialog(
          '',
          'Government regulations require that ePROs only have one question per page.',
          'Ok',
          '',
          false,
          350,
        );
        this.questionService.setQuestionAutoCreateMode(false);
      } else {
        this.autoCreateQuestionOnControlDrop(control.controlType === FormElementsEnum.QuestionGroup);
      }
      return;
    }
    question.questionID = 'Q-' + new Date().getTime();
    if (!question.questionPageUuid) {
      question.questionPageUuid = this.page.page_uuid;
    }

    // Add the dragged control in the auto create flow in the page.
    const control = this.dragDataTemp?.control;
    if (control) {
      if (control.controlType !== FormElementsEnum.QuestionGroup) {
        const page: IFormPage = this.currentForm().body.pages.find(page => page.pageID === this.page.pageID);
        // this is the dragged control

        control.controlID = 'C-' + new Date().getTime();
        control.question_uuid = this.utilsService.generateUUID();
        control.page_uuid = this.page.page_uuid;
        control.pageIndex = page.order;
        control.grid = {
          columnEnd: 'span 4',
          columnStart: 1,
          rowStart: 1,
        };
        control.isDeleted = false;
        question.controls.push(control);
        this.setEditMode({ control, type: control.controlType });
      } else {
        this.setEditMode({ question, type: FormElementsEnum.QuestionGroup });
      }
    }
    this.store.dispatch(formAddQuestion({ question: question }));

    const pageQuestionEntity = { id: question.id, questionID: question.questionID };
    this.dragDataTemp = null;

    // Create a new question if dragging new control outside question
    if (!dropNode) {
      this.page.questions.push(pageQuestionEntity);
    } else {
      // Check the dragged control to be able to calculate the drop position correct.
      if (this.dropNodeTemp) {
        dropNode = this.dropNodeTemp;
      }

      let index = _findIndex(this.page.questions, question => question['questionID'] === dropNode.questionID);

      // if dragged below other questions
      if (index === -1 && this.questionService.isQuestionAutoCreateMode()) {
        index = this.page.questions.length;
      }

      this.page.questions = [
        ...[...this.page.questions.slice(0, index)],
        { ...pageQuestionEntity },
        ...[...this.page.questions.slice(index)],
      ];
    }

    if (control && control.controlType === FormElementsEnum.QuestionGroup) {
      //not sure why this is needed, because it's adding only questionID field to already created question
      // but backend doesn't save it
      this.saveEmptyQuestion(dragData.question);
    } else {
      setTimeout(() => {
        this.updateQuestionAfterControlRendered();
      }, 100);
    }
  }

  private saveEmptyQuestion(question: QuestionModel) {
    if (this.questionService.isQuestionAutoCreateMode()) {
      this.store.dispatch(globalLoading(true));
      this.store.dispatch(updateQuestion({ question: question, isAutoCreate: true }));
    }
  }

  // editTextblockElement(textblock): void {
  //   const dialogRefText = this.dialog.open(MfmTextareaEditorComponent, {
  //     width: '1024px',
  //     height: '768px',
  //     data: {
  //       title: 'Text Block editor',
  //       placeholder: 'TText Block content',
  //       text: textblock.text,
  //       type: 'textarea'
  //     }
  //   });
  //
  //   dialogRefText.afterClosed().pipe(take(1)).subscribe(result => {
  //     if (result) {
  //       const textblockData = { ...textblock, text: result };
  //       this.editElement(textblockData);
  //     }
  //   });
  // }
  //
  // editGridElement(grid): void {
  //   const dialogRef = this.dialog.open(MfmGridEditorComponent, {
  //     width: '100%',
  //     height: '100%',
  //     maxWidth: '100%',
  //     maxHeight: '100%',
  //     data: {
  //       title: 'Dataset View editor',
  //       placeholder: 'Grid content',
  //       text: grid.gridBlock.text,
  //       type: grid.gridBlock.controlType,
  //       grid
  //     }
  //   });
  //
  //   dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
  //     if (result) {
  //       const gridData = { ...grid.gridBlock, label: result.title, ...result };
  //       this.editElement(gridData);
  //     }
  //   });
  // }
  //
  // editPieChartElement(pieChart): void {
  //   const dialogRef = this.dialog.open(MfmPiechartEditorComponent, {
  //     width: '100%',
  //     height: '100%',
  //     maxWidth: '100%',
  //     maxHeight: '100%',
  //     data: {
  //       title: 'Pie chart View editor',
  //       placeholder: 'Pie chart content',
  //       text: pieChart.gridBlock.text,
  //       type: pieChart.gridBlock.controlType,
  //       pieChart
  //     }
  //   });
  //
  //   dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
  //     if (result) {
  //       this.editElement({ ...pieChart.gridBlock, ...result });
  //     }
  //   });
  // }
  //
  // editScriptElement(scriptEl): void {
  //   const dialogRef = this.dialog.open(MfmScriptEditorComponent, {
  //     width: '100%',
  //     height: '100%',
  //     maxWidth: '100%',
  //     maxHeight: '100%',
  //     data: {
  //       title: 'Script component editor',
  //       placeholder: 'Script content',
  //       text: scriptEl.gridBlock.text,
  //       type: scriptEl.gridBlock.controlType,
  //       scriptEl
  //     }
  //   });
  //
  //   dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
  //     if (result) {
  //       const scriptData = { ...scriptEl.gridBlock, label: result.title, ...result };
  //       this.editElement(scriptData);
  //     }
  //   });
  // }
}
