import { Component, computed, effect, inject, Injector, input, InputSignal, OnInit, Signal } from '@angular/core';
import { ControlModel } from '../../../question/control.model';
import { FormElementsEnum } from '../../form-elements.enum';
import { BooleanPreviewComponent } from '../form-components/boolean-preview/boolean-preview.component';
import { AbstractControl, FormControl, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { FormControlActionsComponent } from '../form-control-actions/form-control-actions.component';
import { QuestionModel } from '../../../question/question.model';
import { TextblockPreviewComponent } from '../form-components/textblock-preview/textblock-preview.component';
import { IIntegrationQuestion } from '../../../shared/models/integration/integration-question.interface';
import { JsonPipe } from '@angular/common';
import * as jsonlogic from 'json-logic-js';
import { toSignal } from '@angular/core/rxjs-interop';
import { RuleActionEnum } from '../../../rule/rule-action.enum';
import { TextInputPreviewComponent } from '../form-components/texinput-preview/texinput-preview.component';

@Component({
  selector: 'phar-form-preview-base',
  templateUrl: 'form-preview.base.component.html',
  styleUrl: 'form-preview-base.component.scss',
  standalone: true,
  imports: [
    FormControlActionsComponent,
    ReactiveFormsModule,
    BooleanPreviewComponent,
    TextblockPreviewComponent,
    TextInputPreviewComponent,
  ],
})
export class FormPreviewBaseComponent implements OnInit {
  injector: Injector = inject(Injector);
  control: InputSignal<ControlModel> = input.required<ControlModel>();
  question: InputSignal<QuestionModel> = input.required<QuestionModel>();
  form: InputSignal<UntypedFormGroup> = input.required<UntypedFormGroup>();
  formContainsControl: Signal<boolean> = computed(() => this.form().contains(this.control().question_uuid));
  controlIntegration: Signal<IIntegrationQuestion> = computed(() => {
    return this.question()?.integration[0]?.questions.find(
      (item: IIntegrationQuestion) => item.uuid === this.control().question_uuid,
    );
  });

  replaceLogic(ctrlId: string, conditionJson: any) {
    if (!this.controlIntegration()) null;
    const replaced = JSON.stringify(conditionJson).replace(/REF_\d+/g, ctrlId);

    return JSON.parse(replaced);
  }

  hideLogic = computed(() => {
    if (!this.controlIntegration() || !this.controlIntegration().logics?.length) {
      return null;
    }
    const hide = this.controlIntegration().logics.find(item => item.action === RuleActionEnum.Hide);

    if (!hide) return null;
    return { ...hide, condition: this.replaceLogic(hide.references.question_uuid, hide.condition) };
  });

  showLogic = computed(() => {
    if (!this.controlIntegration() || !this.controlIntegration().logics?.length) {
      return null;
    }
    const show = this.controlIntegration().logics.find(item => item.action === RuleActionEnum.Show);

    if (!show) return null;
    return this.replaceLogic(show.references.question_uuid, show.condition);
  });

  formValues: Signal<Record<string, any>>;

  show = computed(() => {
    if (!this.showLogic()) return true;
    return jsonlogic.apply(this.showLogic(), this.formValues());
  });

  hide = computed(() => {
    if (!this.hideLogic()) return false;
    return jsonlogic.apply(this.hideLogic().condition, this.formValues());
  });

  ngOnInit() {
    this.formValues = toSignal(this.form().valueChanges, {
      injector: this.injector,
      initialValue: () => this.form()?.value,
    });
    this.bindToForm();

    effect(
      () => {
        if (this.hide() || !this.show()) {
          const ctrl: AbstractControl = this.form().get(this.control().question_uuid);
          ctrl.setValue(null);
          ctrl.markAsPristine({ emitEvent: true });
          ctrl.markAsTouched({ emitEvent: true });
        }
      },
      {
        allowSignalWrites: true,
        injector: this.injector,
      },
    );
  }

  private bindToForm(): void {
    if (!this.formContainsControl()) {
      //initially, everything is required;
      this.form().addControl(this.control().question_uuid, new FormControl(null, [Validators.required]));
    }
  }

  protected readonly FormElementsEnum = FormElementsEnum;
}
