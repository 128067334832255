<div class="phone">
  <div class="body">
    <div class="screen-background">
      <div class="screen-viewport">
        <div class="camera">
          <div class="camera-circle-border"></div>
        </div>
        <div class="screen-viewport-content">
          <ng-content></ng-content>
        </div>
        <div class="navigation-bar">
          <div class="navigation-bar-circle"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="right-buttons">
    <div class="volume-button"></div>
    <div class="power-button"></div>
  </div>
</div>
