import { Component, computed, input, InputSignal, Signal } from '@angular/core';
import { IFormPage } from '../../../form.model';
import { QuestionModel } from '../../../../question/question.model';
import { NgArrayPipesModule } from 'ngx-pipes';
import { FormPreviewBaseComponent } from '../../form-preview-base/form-preview-base.component';
import { UntypedFormGroup } from '@angular/forms';
import { ControlModel } from '../../../../question/control.model';

@Component({
  selector: 'phar-form-preview-content-page',
  templateUrl: 'form-preview-content-page.component.html',
  styleUrl: 'form-preview-content-page.component.scss',
  standalone: true,
  imports: [NgArrayPipesModule, FormPreviewBaseComponent],
})
export class FormPreviewContentPageComponent {
  page: InputSignal<IFormPage> = input.required<IFormPage>();
  form: InputSignal<UntypedFormGroup> = input.required<UntypedFormGroup>();

  questions: InputSignal<QuestionModel[]> = input.required<QuestionModel[]>();
  activeQuestions: Signal<QuestionModel[]> = computed(() => {
    return this.questions()
      .filter(question => !question.isDeleted)
      .map(question => {
        return {
          ...question,
          controls: question.controls.filter((c: ControlModel) => !c.isDeleted && c.controlType !== 'dropzone'),
        };
      });

  });
}
