import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormModel, IFormPage } from '../form.model';
import { orderBy } from 'lodash-es';
import { QuestionModel } from '../../question/question.model';

@Injectable({
  providedIn: 'root',
})
export class FormPreviewPaginationService {
  form: WritableSignal<FormModel | null> = signal(null);
  readonly availablePages: Signal<IFormPage[]> = computed(() => {
    return orderBy(
      this.form().body.pages.filter((page: IFormPage) => !page.isDeleted),
      'order',
      ['asc'],
    );
  });
  readonly activePage: Signal<IFormPage> = computed(() => {
    return this.availablePages()[this.activePageIndex()];
  });
  readonly activePageQuestions: Signal<QuestionModel[]> = computed(() => {
    if (!this.activePage()) return [];
    return this.activePage().questions.map(({ id }) => this.form().questionsPopulated[id]);
  });
  readonly activePageIndex: WritableSignal<number> = signal<number>(0);

  hasNextPage = computed<boolean>(() => {
    return !!this.availablePages()[this.activePageIndex() + 1];
  });

  hasPrevPage = computed<boolean>(() => {
    return !!this.availablePages()[this.activePageIndex() - 1];
  });

  nextPage(): void {
    if (!this.hasNextPage()) return;
    this.activePageIndex.update(index => index + 1);
  }

  prevPage(): void {
    if (!this.hasPrevPage()) return;
    this.activePageIndex.update(index => index - 1);
  }
}
