<phar-form-preview-dialog-template [displayActions]="false" (close)="close()">
  <div title>{{ activeSidebarTitle() }}</div>
  <div sidebar>
    <phar-form-preview-sidebar
      [groupItems]="sidebarItems"
      [activeItem]="activeSidebarItem()"
      (itemClicked)="updateActiveItem($event)" />
  </div>

  <div class="h-100 p-3 pt-4 preview-dialog-body" body>
    <div class="pb-1 h-100">
      <phar-mobile-form-preview-content [mobileView]="activeSidebarItem()" [form]="data.form" />
    </div>
  </div>
</phar-form-preview-dialog-template>
