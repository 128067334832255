import { Component, input, InputSignal } from '@angular/core';
import { ControlModel } from '../../../question/control.model';
import { QuestionModel } from '../../../question/question.model';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'phar-form-control-actions',
  templateUrl: 'form-control-actions.component.html',
  styleUrl: 'form-control-actions.component.scss',
  standalone: true,
  imports: [MatIcon],
})
export class FormControlActionsComponent {
  control: InputSignal<ControlModel> = input<ControlModel>();
  question: InputSignal<QuestionModel> = input<QuestionModel>();

  actions = [
    {
      icon: 'clock-rewind',
    },
    {
      icon: 'message-question-square',
    },
    {
      icon: 'switch',
    },
  ];
}
