/* src/app/form/form-preview/mobile-form-preview-content/mobile-form-preview-content.component.scss */
.phone-content {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
}
.phone-content-header_title-wrapper {
  padding: 16px;
  border-bottom: 1px solid #D7D7D7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.phone-content-header_title-wrapper_title {
  font-size: 20px;
  font-weight: 500;
}
.phone-content-header_title-wrapper_close {
  display: flex;
  align-items: center;
}
.phone-content-header_title-wrapper_close .close-icon {
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
}
.phone-content-header_progress {
  padding: 16px;
}
.phone-content-body {
  padding: 16px;
  overflow: auto;
  flex: 1;
}
.phone-content-paginator {
  padding: 16px;
  border-top: 1px solid #D7D7D7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::ng-deep .phone-content-paginator_button {
  padding: 0;
  min-width: 40px;
  min-height: 40px;
}
::ng-deep .phone-content-paginator_options-icon {
  transform: rotate(90deg);
}
/*# sourceMappingURL=mobile-form-preview-content.component.css.map */
