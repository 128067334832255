import { Component } from '@angular/core';
import { FormPreviewContentPageComponent } from '../../form-preview-content/form-content-page/form-preview-content-page.component';

@Component({
  selector: 'phar-mobile-form-preview-content-page',
  templateUrl: 'mobile-form-preview-content-page.component.html',
  styleUrl: 'mobile-form-preview-content-page.component.scss',
  standalone: true,
})
export class MobileFormPreviewContentPageComponent extends FormPreviewContentPageComponent {}
