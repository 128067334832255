<div class="phone">
  <div class="left-buttons">
    <div class="silence-button"></div>
    <div class="plus-button"></div>
    <div class="minus-button"></div>
  </div>
  <div class="body">
    <div class="notch">
      <div data-svg-wrapper class="notch-svg-wrapper">
        <svg width="202" height="44" viewBox="0 0 202 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.667969 0.976562H201.21V14.9446H180.257C177.502 14.9446 175.269 17.1781 175.269 19.9332V22.9264C175.269 34.4979 165.888 43.8785 154.317 43.8785H47.5608C35.9892 43.8785 26.6087 34.4979 26.6087 22.9264V19.9332C26.6087 17.1781 24.3752 14.9446 21.6201 14.9446H0.667969V0.976562Z"
            fill="black" />
        </svg>
      </div>
      <div class="notch-camera"></div>
    </div>

    <div class="screen-background">
      <div class="screen-viewport">
        <div class="screen-viewport-content">
          <ng-content></ng-content>
        </div>
        <div class="navigation-bar">
          <div class="navigation-bar-pill"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-buttons">
    <div class="power-button"></div>
  </div>
</div>
