<div class="control-container">
  <ng-container [formGroup]="form()">
    @if (control()) {
      @let controlModel = control();
      @let controlId = controlModel.controlID;
      @if (show() && !hide()) {
        @switch (control().controlType) {
          @case (FormElementsEnum.Boolean) {
            <phar-boolean-preview
              [controlModel]="controlModel"
              [integration]="controlIntegration()"
              [formControlName]="control().question_uuid" />
          }
          @case (FormElementsEnum.TextBlock) {
            <phar-textblock-preview [controlModel]="controlModel" [integration]="controlIntegration()" />
          }
          @case (FormElementsEnum.TextInput) {
            <phar-text-input-preview
              [controlModel]="controlModel"
              [integration]="controlIntegration()"
              [formControlName]="control().question_uuid" />
          }

          @default {
            <h3>Component for type {{ control().controlType }} is not implemented yet</h3>
          }
        }
        <phar-form-control-actions [control]="control()" [question]="question()" />
      }
    }
  </ng-container>
</div>
