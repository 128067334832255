import { Component, inject, input, OnInit } from '@angular/core';
import { FormModel } from '../../form.model';
import { UntypedFormGroup } from '@angular/forms';
import { FormPreviewContentPageComponent } from './form-content-page/form-preview-content-page.component';
import { slideInOut } from '../../../animations';
import { BaseComponent } from '../../../shared/base.class';
import { takeUntil } from 'rxjs/operators';
import { FormPreviewPaginationService } from '../form-preview-pagination.service';

@Component({
  selector: 'phar-form-preview-content',
  templateUrl: 'form-preview-content.component.html',
  styleUrl: 'form-preview-content.component.scss',
  standalone: true,
  imports: [FormPreviewContentPageComponent],
  animations: [slideInOut],
})
export class FormPreviewContentComponent extends BaseComponent implements OnInit {
  form = input.required<FormModel>();
  formPreviewService = inject(FormPreviewPaginationService);
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      console.log('FORM CONTROL VALUES', this.formGroup.value);
    });
  }
}
