<div class="phone">
  <div class="left-buttons">
    <div class="silence-button"></div>
    <div class="plus-button"></div>
    <div class="minus-button"></div>
  </div>
  <div class="body">
    <div class="screen-background">
      <div class="notch"></div>
      <div class="dynamic-island">
        <div class="dynamic-island-camera"></div>
      </div>

      <div class="screen-viewport">
        <div class="screen-viewport-content">
          <ng-content></ng-content>
        </div>
        <div class="navigation-bar">
          <div class="navigation-bar-pill"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-buttons">
    <div class="power-button"></div>
  </div>
</div>
