/* src/app/form/form-preview/phone-template/iphone-13-template/iphone-13-template.component.scss */
.phone {
  display: flex;
}
.phone .body {
  position: relative;
  border-radius: 65px;
  background: #7091BA;
  box-shadow: 0px 0px 0.998px 0.998px #0F3670 inset, 0px 0px 0px 2.993px #FFF inset;
  padding: 6px;
}
.phone .body .notch {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 200.54px;
  height: 43px;
}
.phone .body .notch-svg-wrapper {
  position: absolute;
  left: 0px;
  top: 0px;
}
.phone .body .notch-camera {
  position: absolute;
  left: 41.25px;
  top: 19px;
  width: 13.5px;
  height: 13.5px;
  opacity: 0.7;
  background:
    radial-gradient(
      100% 100%,
      black 21%,
      #081a32 27%,
      #1f3a58 40%,
      #375f90 52%,
      #274871 71%,
      #0d1528 92%),
    conic-gradient(
      from 180deg at 0% 100%,
      rgba(0, 0, 0, 0) 148deg,
      black 184deg,
      rgba(0, 0, 0, 0) 294deg),
    conic-gradient(
      from 180deg at 0% 100%,
      black 0deg,
      rgba(0, 0, 0, 0) 60deg,
      rgba(0, 0, 0, 0) 319deg);
  border-radius: 9999px;
  border: 0.51px #080f21 solid;
}
.phone .body .screen-background {
  border-radius: 60.861px;
  border: 0.998px solid #454545;
  background: #000;
  padding: 15px;
}
.phone .body .screen-background .screen-viewport {
  height: 844px;
  width: 390px;
  background: white;
  border-radius: 44px;
  padding-top: 57px;
}
.phone .body .screen-background .screen-viewport-content {
  height: calc(100% - 16px);
}
.phone .body .screen-background .screen-viewport .navigation-bar {
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.phone .body .screen-background .screen-viewport .navigation-bar-pill {
  width: 72px;
  height: 2px;
  background: rgba(0, 0, 0, 0.54);
  border-radius: 4px;
}
.phone .left-buttons,
.phone .right-buttons {
  display: flex;
  flex-direction: column;
}
.phone .left-buttons *[class$=-button],
.phone .right-buttons *[class$=-button] {
  width: 4.5px;
  flex-shrink: 0;
  border-radius: 1.39px;
  border: 0.521px solid #7F7E79;
  background: #666560;
  box-shadow:
    0px -1.042px 0px 0px #BEBDB8 inset,
    0px 1.042px 0px 0px #BEBDB8 inset,
    0px 2.78px 0.695px 0px #282722 inset,
    0px -2.78px 0.695px 0px #282722 inset,
    1.737px 0px 1.39px 0px rgba(152, 151, 146, 0.6) inset,
    1.39px 0px 0px 0px #92918C inset;
}
.phone .left-buttons .silence-button,
.phone .right-buttons .silence-button {
  height: 31px;
}
.phone .left-buttons .plus-button,
.phone .left-buttons .minus-button,
.phone .right-buttons .plus-button,
.phone .right-buttons .minus-button {
  height: 60px;
}
.phone .left-buttons .plus-button,
.phone .right-buttons .plus-button {
  margin-top: 27px;
  margin-bottom: 17px;
}
.phone .left-buttons .power-button,
.phone .right-buttons .power-button {
  height: 94px;
}
.phone .left-buttons {
  margin-top: 154px;
}
.phone .right-buttons {
  margin-top: 233px;
}
/*# sourceMappingURL=iphone-13-template.component.css.map */
