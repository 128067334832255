import { Component, forwardRef, input, InputSignal, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormPreviewMode } from '../../form-preview-mode.enum';
import { FormControlBase } from '../form-control-base';
import { MatRadioButton, MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { PreviewComponentTemplateComponent } from '../preview-component-template/preview-component-template.component';

@Component({
  selector: 'phar-boolean-preview',
  templateUrl: 'boolean-preview.component.html',
  styleUrl: 'boolean-preview.component.scss',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, MatRadioGroup, MatRadioButton, PreviewComponentTemplateComponent],
  host: {
    '(blur)': 'handleBlur()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanPreviewComponent),
      multi: true,
    },
  ],
})
export class BooleanPreviewComponent extends FormControlBase implements ControlValueAccessor {
  value = signal<null | string>(null);
  disabled = signal<boolean>(false);
  mode: InputSignal<FormPreviewMode> = input<FormPreviewMode>(FormPreviewMode.Desktop);
  touched = false;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  writeValue(valueToWrite: string): void {
    this.value.set(valueToWrite);
  }

  valueChanged(event: MatRadioChange): void {
    this.value.set(event.value);
    this.onChange(event.value);
  }

  private onTouched?() {}
  private onChange?(_: unknown) {}

  protected handleBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
