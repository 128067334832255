/* src/app/form/form-preview/phone-template/samsung-galaxy-template/samsung-galaxy-template.component.scss */
.phone {
  display: flex;
}
.phone .body {
  position: relative;
  border: 5.25px solid #515151;
  background: #4B4B4B;
  padding: 3.5px;
  border-radius: 58px;
}
.phone .body .screen-background {
  border: 2.5px solid #353535;
  background: #2E2E2E;
  padding: 6.5px;
  border-radius: 50px;
}
.phone .body .screen-background .screen-viewport {
  position: relative;
  width: 382px;
  height: 834px;
  background: white;
  border-radius: 44px;
  padding-top: 32px;
  overflow: hidden;
}
.phone .body .screen-background .screen-viewport .camera {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6px;
  width: 20px;
  height: 20px;
  background-color: #252527;
  border-radius: 50%;
}
.phone .body .screen-background .screen-viewport .camera-circle-border {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #353535;
  background-color: transparent;
}
.phone .body .screen-background .screen-viewport-content {
  height: calc(100% - 43px);
}
.phone .body .screen-background .screen-viewport .navigation-bar {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0E4695;
}
.phone .body .screen-background .screen-viewport .navigation-bar-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
}
.phone .right-buttons {
  margin-top: 148px;
}
.phone .right-buttons .volume-button,
.phone .right-buttons .power-button {
  background: #2E2E2E;
  width: 4.1px;
}
.phone .right-buttons .volume-button {
  height: 111px;
}
.phone .right-buttons .power-button {
  margin-top: 58px;
  height: 61px;
}
/*# sourceMappingURL=samsung-galaxy-template.component.css.map */
