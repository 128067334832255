<phar-preview-component-template>
  <ng-container label>
    {{ integration().code }}
  </ng-container>
  <ng-container values>
    <mat-form-field class="textarea">
      <textarea
        matInput
        placeholder="Enter your answer"
        [rows]="9"
        [maxLength]="500"
        [cols]="15"
        [value]="value()"
        (input)="valueChanged($event)"></textarea>
      <mat-hint>{{ value()?.length || 0 }}/500</mat-hint>
    </mat-form-field>
  </ng-container>
</phar-preview-component-template>
