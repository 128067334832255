<div class="page-container h-100 mt-3">
  <div class="page-content">
    @if (formPreviewService.activePage()) {
      <phar-form-preview-content-page
        [form]="formGroup"
        [page]="formPreviewService.activePage()"
        [questions]="formPreviewService.activePageQuestions()" />
    }
  </div>
</div>
