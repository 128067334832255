/* src/app/form/form-preview/progress-bar/progress-bar.component.scss */
.progress-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.progress-container .progress-percentage {
  font-weight: 500;
}
.progress-container .progress-bar {
  height: 8px;
  width: 100%;
  background-color: #D7D7D7;
  border-radius: 8px;
}
.progress-container .progress-bar .progress-fill {
  height: 100%;
  background-color: #5466A6;
  border-radius: 8px;
  transition: width 0.3s ease-in-out;
}
/*# sourceMappingURL=progress-bar.component.css.map */
