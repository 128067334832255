import { Component, inject, input, OnInit } from '@angular/core';
import { PhoneTemplateComponent } from '../phone-template/phone-template.component';
import { MobileViewsEnum } from '../mobile-preview/mobile-views.enum';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { FormModel } from '../../form.model';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { ButtonComponent } from '../../../shared/phar-button/phar-button.component';
import { FormPreviewPaginationService } from '../form-preview-pagination.service';
import { MobileFormPreviewContentPageComponent } from './mobile-content-page/mobile-form-preview-content-page.component';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/base.class';

@Component({
  selector: 'phar-mobile-form-preview-content',
  templateUrl: 'mobile-form-preview-content.component.html',
  styleUrls: ['mobile-form-preview-content.component.scss'],
  standalone: true,
  imports: [
    PhoneTemplateComponent,
    MatIcon,
    MatIconButton,
    ProgressBarComponent,
    ButtonComponent,
    MobileFormPreviewContentPageComponent,
  ],
})
export class MobileFormPreviewContentComponent extends BaseComponent implements OnInit {
  mobileView = input.required<MobileViewsEnum>();
  form = input.required<FormModel>();
  formPreviewService = inject(FormPreviewPaginationService);
  formGroup: UntypedFormGroup = new UntypedFormGroup({});

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      console.log('FORM CONTROL VALUES', this.formGroup.value);
    });
  }
}
