import {
  Component,
  effect,
  inject,
  Injector,
  input,
  InputSignal,
  OnInit,
  output,
  OutputEmitterRef,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { expandCollapseDetails } from '../../../animations';
import { ISidebarGroup, ISidebarGroupItem } from './sidebar-group.interface';
import { NgClass } from '@angular/common';

@Component({
  selector: 'phar-form-preview-sidebar',
  templateUrl: 'form-preview-sidebar.component.html',
  styleUrl: 'form-preview-sidebar.component.scss',
  standalone: true,
  imports: [MatIcon, NgClass],
  animations: [expandCollapseDetails],
})
export class FormPreviewSidebarComponent implements OnInit {
  expandCollapseMap = new Map<string, boolean>();
  groupItems: InputSignal<ISidebarGroup[]> = input.required<ISidebarGroup[]>();
  activeItem: InputSignal<string> = input<string>();
  itemClicked: OutputEmitterRef<string> = output<string>();
  injector: Injector = inject(Injector);
  ngOnInit() {
    effect(
      () => {
        if (this.activeItem()) {
          const group = this.groupItems().find(group => group.items.find(item => item.name === this.activeItem()));
          if (group) {
            this.expandCollapseMap.set(group.name, true);
          }
        }
      },
      {
        allowSignalWrites: true,
        injector: this.injector,
      },
    );
  }

  expandCollapseGroup(groupName: string) {
    this.expandCollapseMap.set(groupName, !this.expandCollapseMap.get(groupName));
  }

  activateItem(item: ISidebarGroupItem): void {
    this.itemClicked.emit(item.name);
  }
}
