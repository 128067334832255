import { Component, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { NgClass } from '@angular/common';

@Component({
  selector: 'phar-form-preview-dialog-template',
  templateUrl: 'form-preview-dialog-template.component.html',
  styleUrl: 'form-preview-dialog-template.component.scss',
  standalone: true,
  imports: [MatIcon, MatIconButton, NgClass],
})
export class FormPreviewDialogTemplateComponent {
  displayActions = input(true);
  close = output();
}
