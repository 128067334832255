import { Component, forwardRef, input, InputSignal, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlBase } from '../form-control-base';
import { FormPreviewMode } from '../../form-preview-mode.enum';
import { MatRadioChange } from '@angular/material/radio';
import { PreviewComponentTemplateComponent } from '../preview-component-template/preview-component-template.component';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'phar-text-input-preview',
  templateUrl: 'texinput-preview.component.html',
  styleUrl: 'texinput-preview.component.scss',
  standalone: true,
  host: {
    '(blur)': 'handleBlur()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputPreviewComponent),
      multi: true,
    },
  ],
  imports: [PreviewComponentTemplateComponent, MatFormField, MatInput, MatHint],
})
export class TextInputPreviewComponent extends FormControlBase implements ControlValueAccessor {
  value = signal<null | string>(null);
  disabled = signal<boolean>(false);
  mode: InputSignal<FormPreviewMode> = input<FormPreviewMode>(FormPreviewMode.Desktop);
  touched = false;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  writeValue(valueToWrite: string): void {
    this.value.set(valueToWrite);
  }

  valueChanged(event: InputEvent): void {
    this.value.set(event.target['value']);
    this.onChange(event.target['value']);
  }

  private onTouched?() {}
  private onChange?(_: unknown) {}

  protected handleBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
