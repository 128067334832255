<phar-form-preview-dialog-template (close)="close()">
  <div title>{{ activeSidebarItem() }}</div>
  <div sidebar>
    <phar-form-preview-sidebar
      [groupItems]="sidebarItems"
      [activeItem]="activeSidebarItem()"
      (itemClicked)="updateActiveItem($event)" />
  </div>

  <div class="h-100" body>
    <phar-form-preview-content [form]="data.form" />
  </div>
  <div class="page-actions d-flex align-items-center h-100" actions>
    <phar-button class="mx-auto" [disabled]="true">
      <mat-icon [svgIcon]="'check'" />
      Save & Close
    </phar-button>
    @if (formPreviewService.hasNextPage()) {
      <phar-button
        [state]="'success'"
        [disabled]="!formPreviewService.hasNextPage()"
        (click)="formPreviewService.nextPage()">
        <mat-icon [svgIcon]="'arrow-right'" />
        Continue
      </phar-button>
    } @else {
      <phar-button
        [state]="'success'"
        [disabled]="!formPreviewService.hasPrevPage()"
        (click)="formPreviewService.prevPage()">
        <mat-icon [svgIcon]="'arrow-left'" />
        Back
      </phar-button>
    }
  </div>
</phar-form-preview-dialog-template>
