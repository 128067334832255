/* src/app/form/form-preview/form-preview-sidebar/form-preview-sidebar.component.scss */
.section-title {
  color: #babbbd;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 8px;
  border-radius: 8px;
}
.group-item {
  padding: 8px;
  border-radius: 8px;
}
.group-item-title {
  color: #babbbd;
}
.group-item.active {
  background: #e4e7f1;
}
.group-item.active .group-item-title {
  color: #5466a6;
  font-weight: 500;
}
/*# sourceMappingURL=form-preview-sidebar.component.css.map */
