import { Component, input } from '@angular/core';
import { MobileViewsEnum } from '../mobile-preview/mobile-views.enum';
import { Iphone15ProTemplateComponent } from './iphone-15-pro-template/iphone-15-pro-template.component';
import { NgTemplateOutlet } from '@angular/common';
import { Iphone13TemplateComponent } from './iphone-13-template/iphone-13-template.component';
import { SamsungGalaxyTemplateComponent } from './samsung-galaxy-template/samsung-galaxy-template.component';

@Component({
  selector: 'phar-phone-template',
  templateUrl: 'phone-template.component.html',
  styleUrls: ['phone-template.component.scss'],
  standalone: true,
  imports: [Iphone15ProTemplateComponent, NgTemplateOutlet, Iphone13TemplateComponent, SamsungGalaxyTemplateComponent],
})
export class PhoneTemplateComponent {
  mobileView = input.required<MobileViewsEnum>();
  protected readonly MobileViewsEnum = MobileViewsEnum;
}
