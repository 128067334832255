<div class="form-previewer-container">
  <div class="form-previewer-sidebar">
    <ng-content select="[sidebar]" />
  </div>
  <div class="form-previewer-content" [ngClass]="{ 'with-actions-row': displayActions() }">
    <div class="form-previewer-content-header d-flex justify-content-between align-items-center">
      <div class="form-previewer-content-header_title">
        <ng-content select="[title]" />
      </div>
      <div class="form-previewer-content-header_close">
        <button class="close-icon" mat-icon-button (click)="close.emit()">
          <mat-icon class="button-block_small-icon" svgIcon="close" />
        </button>
      </div>
    </div>
    <div class="form-previewer-content-body overflow-auto">
      <ng-content select="[body]" />
    </div>
    <div class="form-previewer-content-actions h-100">
      <ng-content select="[actions]" />
    </div>
  </div>
</div>
