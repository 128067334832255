<div class="qg-container d-flex flex-column gap-4">
  <div class="actions ms-auto d-flex flex-row gap-5">
    <div class="text-center">Queries</div>
    <div class="text-center">SDV</div>
    <div class="text-center">Lock</div>
  </div>
  @for (q of activeQuestions(); track q.questionGuid) {
    <div class="qg-control d-flex flex-column gap-4">
      @for (control of q.controls | orderBy: 'grid.rowStart'; track control.controlID) {
        <phar-form-preview-base [form]="form()" [question]="q" [control]="control" />
      }
    </div>
  }
</div>
