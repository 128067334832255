/* src/app/form/form-preview/form-components/texinput-preview/texinput-preview.component.scss */
.textarea textarea {
  resize: none;
  font-size: 16px;
  font-weight: 400;
  color: #272931;
}
.textarea ::ng-deep .mat-mdc-text-field-wrapper {
  background: #fbfbfb;
  padding: 0 !important;
}
.textarea ::ng-deep div.mat-mdc-form-field-infix {
  padding: 8px 8px 8px 12px !important;
}
.textarea ::ng-deep .mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
  color: rgba(39, 41, 49, 0.6);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
/*# sourceMappingURL=texinput-preview.component.css.map */
