/* src/app/form/form-preview/form-preview-dialog-template/form-preview-dialog-template.component.scss */
:host {
  height: 100%;
}
.form-previewer-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 280px 1fr;
}
.form-previewer-sidebar {
  background: #fbfbfb;
  padding: 35px 35px 0 14px;
}
.form-previewer-content {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 32px 1fr;
  overflow: auto;
}
.form-previewer-content.with-actions-row {
  grid-template-rows: 32px 1fr 72px;
}
.form-previewer-content-header_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
/*# sourceMappingURL=form-preview-dialog-template.component.css.map */
