import { Component } from '@angular/core';
import { FormControlBase } from '../form-control-base';
import { PreviewComponentTemplateComponent } from '../preview-component-template/preview-component-template.component';

@Component({
  selector: 'phar-textblock-preview',
  templateUrl: 'textblock-preview.component.html',
  standalone: true,
  imports: [PreviewComponentTemplateComponent],
})
export class TextblockPreviewComponent extends FormControlBase {}
