<phar-preview-component-template>
  <ng-container label>
    {{ integration().code }}
  </ng-container>
  <ng-container values>
    <mat-radio-group class="d-flex flex-column" (change)="valueChanged($event)">
      @for (item of integration().translations[0].labels; track item.order; let i = $index) {
        <mat-radio-button color="primary" [value]="item.order.toString()">{{ item.name }}</mat-radio-button>
      }
    </mat-radio-group>
  </ng-container>
</phar-preview-component-template>
