<phar-phone-template [mobileView]="mobileView()">
  <div class="phone-content h-100">
    <div class="phone-content-header">
      <div class="phone-content-header_title-wrapper">
        <div class="phone-content-header_title-wrapper_title">
          {{ form().name }}
        </div>

        <div class="phone-content-header_title-wrapper_close">
          <button class="close-icon" mat-icon-button>
            <mat-icon svgIcon="close" />
          </button>
        </div>
      </div>

      <div class="phone-content-header_progress">
        <phar-progress-bar [total]="10" [current]="1" />
      </div>
    </div>

    <div class="phone-content-body">
      @if (formPreviewService.activePage()) {
        <phar-mobile-form-preview-content-page
          [form]="formGroup"
          [page]="formPreviewService.activePage()"
          [questions]="formPreviewService.activePageQuestions()" />
      }
    </div>

    <div class="phone-content-paginator">
      <phar-button
        [type]="'stroked'"
        [class]="'phone-content-paginator_button'"
        [disabled]="!formPreviewService.hasPrevPage()"
        (click)="formPreviewService.prevPage()">
        <mat-icon class="me-0" [svgIcon]="'arrow-left'" />
      </phar-button>

      <phar-button [type]="'flat'" [class]="'phone-content-paginator_button'">
        <mat-icon class="me-0 phone-content-paginator_options-icon" [svgIcon]="'more'" />
      </phar-button>

      <phar-button
        [type]="'stroked'"
        [class]="'phone-content-paginator_button'"
        [disabled]="!formPreviewService.hasNextPage()"
        (click)="formPreviewService.nextPage()">
        <mat-icon class="me-0" [svgIcon]="'arrow-right'" />
      </phar-button>
    </div>
  </div>
</phar-phone-template>
