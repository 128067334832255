@for (group of groupItems(); track group.name) {
  <div
    class="d-flex justify-content-between cursor-pointer align-items-center position-relative"
    (click)="expandCollapseGroup(group.name)">
    <div class="section-title">{{ group.name }}</div>
    <mat-icon [svgIcon]="expandCollapseMap.get(group.name) ? 'arrow-up' : 'arrow-down'" />
  </div>
  @for (item of group.items; track item.name) {
    <div
      class="d-flex flex-column gap-1 mt-2"
      [@expandCollapseDetails]="expandCollapseMap.get(group.name) ? 'expanded' : 'collapsed'">
      <div class="group-item d-flex justify-content-between" [ngClass]="{ active: activeItem() === item.name }">
        <div class="group-item-title cursor-pointer" (click)="activateItem(item)">{{ item.name }}</div>
        <mat-icon>more_vert</mat-icon>
      </div>
    </div>
  }
}
