@switch (mobileView()) {
  @case (MobileViewsEnum.IPhone2) {
    <phar-iphone-15-pro-template>
      <ng-container *ngTemplateOutlet="mainContent" />
    </phar-iphone-15-pro-template>
  }
  @case (MobileViewsEnum.IPhone1) {
    <phar-iphone-13-template>
      <ng-container *ngTemplateOutlet="mainContent" />
    </phar-iphone-13-template>
  }
  @case (MobileViewsEnum.Generic) {
    <phar-samsung-galaxy-template>
      <ng-container *ngTemplateOutlet="mainContent" />
    </phar-samsung-galaxy-template>
  }
}

<ng-template #mainContent><ng-content /></ng-template>
