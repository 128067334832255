import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  Inject,
  OnDestroy,
  signal,
  ViewChild,
} from '@angular/core';
import { FormPreviewDialogTemplateComponent } from '../form-preview-dialog-template/form-preview-dialog-template.component';
import { FormPreviewSidebarComponent } from '../form-preview-sidebar/form-preview-sidebar.component';
import { ISidebarGroup } from '../form-preview-sidebar/sidebar-group.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormModel } from '../../form.model';
import { FormPreviewPaginationService } from '../form-preview-pagination.service';
import { MobileViewsEnum } from './mobile-views.enum';
import { MobileFormPreviewContentComponent } from '../mobile-form-preview-content/mobile-form-preview-content.component';

@Component({
  templateUrl: 'mobile-form-previewer.component.html',
  styleUrls: ['mobile-form-previewer.component.scss'],
  standalone: true,
  imports: [FormPreviewDialogTemplateComponent, FormPreviewSidebarComponent, MobileFormPreviewContentComponent],
})
export class MobileFormPreviewerComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MobileFormPreviewContentComponent, { read: ElementRef }) mobilePreviewContent: ElementRef;
  activeSidebarItem = signal(MobileViewsEnum.IPhone2);
  formPreviewService = inject(FormPreviewPaginationService);
  sidebarItems: ISidebarGroup[] = [
    { name: 'IOS', items: [{ name: MobileViewsEnum.IPhone2 }, { name: MobileViewsEnum.IPhone1 }] },
    { name: 'Android', items: [{ name: MobileViewsEnum.Generic }] },
  ];
  sidebarItemsTitleMap = {
    [MobileViewsEnum.IPhone1]: `${MobileViewsEnum.IPhone1} (notch)`,
    [MobileViewsEnum.IPhone2]: `${MobileViewsEnum.IPhone2} (dynamic island)`,
    [MobileViewsEnum.Generic]: `Samsung Galaxy S23`,
  };
  activeSidebarTitle = computed(() => this.sidebarItemsTitleMap[this.activeSidebarItem()]);
  private resizeObserver!: ResizeObserver;

  constructor(
    private dialogRef: MatDialogRef<MobileFormPreviewerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      form: FormModel;
    },
  ) {
    this.formPreviewService.form.set(this.data.form);
  }

  close(): void {
    this.dialogRef.close();
  }

  updateActiveItem(item: string): void {
    this.activeSidebarItem.set(item as MobileViewsEnum);
  }

  ngAfterViewInit() {
    const parent = this.mobilePreviewContent.nativeElement.parentElement;
    const child = this.mobilePreviewContent.nativeElement;

    if (!parent) return;

    this.resizeObserver = new ResizeObserver(() => {
      this.updateScale(parent, child);
    });

    this.resizeObserver.observe(parent);
    this.updateScale(parent, child);
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private updateScale(parent: HTMLElement, child: HTMLElement) {
    const parentHeight = parent.clientHeight;
    const childHeight = child.offsetHeight;

    const scale = Math.min(Math.max(parentHeight / childHeight, 0.6), 1);
    child.style.zoom = `${scale}`;
  }
}
