/* src/app/form/form-preview/form-preview-content/form-content-page/form-preview-content-page.component.scss */
.actions {
  color: #86878c;
  min-height: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.actions div {
  width: 70px;
}
/*# sourceMappingURL=form-preview-content-page.component.css.map */
