import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, map, takeUntil } from 'rxjs/operators';

import { globalLoading } from '../../store/actions/ui.actions';
import { AppState } from '../../store/models/app.state';
import { resetCurrentForm, searchFormById } from '../store/form.actions';
import { FormModel } from '../form.model';
import { slideInOut } from '../../animations';
import { HeaderService } from '../../layout/phar-header/header.service';
import { EntityType } from '../../core/models/entity-type-enum';
import { FormRouteData, NavigateBackLinkInfo } from '../../core/models/route-data.interface';
import { BaseComponent } from '../../shared/base.class';
import { selectCurrentFormStateForm, selectCurrentFormStateFormSnapshot } from '../store/form.state';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsEnum } from '../../permission/permissions.enum';
import { FormTypePipe } from '../pipes/form-type.pipe';

@Component({
  templateUrl: './form-context.component.html',
  styleUrls: ['./form-context.component.scss'],
  animations: [slideInOut],
})
export class FormContextComponent extends BaseComponent implements OnInit, OnDestroy {
  EntityType = EntityType;
  sidebar$: Observable<string>;
  header$: Observable<boolean>;
  form$: Observable<FormModel>;
  formSnapshot$: Observable<FormModel>;
  formId: number;
  formTypePipe = inject(FormTypePipe);
  secondLevelLinks = [
    {
      text: 'Properties',
      url: 'details',
    },
    {
      text: 'Questions',
      url: 'content',
    },
    // {
    //   text: 'Settings',
    //   iconClass: 'icon-equalizer',
    //   url: 'settings'
    // },
    {
      text: 'Approvals',
      url: 'approvals',
    },
    {
      text: 'Export',
      url: 'export-form',
      permission: PermissionsEnum.ExportECOA,
    },
  ];
  navigateBackLinkInfo$: Observable<NavigateBackLinkInfo>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private headerService: HeaderService,
    private activatedRoute: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formId = parseInt(this.route.snapshot.paramMap.get('formId'), 10);
    this.sidebar$ = this.store.select(s => s.ui.sidebar);
    this.header$ = this.store.select(state => state.ui.header);
    this.form$ = this.store.select(selectCurrentFormStateForm);
    this.formSnapshot$ = this.store.select(selectCurrentFormStateFormSnapshot);
    this.navigateBackLinkInfo$ = this.activatedRoute.data.pipe(
      map(({ navigateBackLink }: FormRouteData) => navigateBackLink),
    );

    if (this.formId) {
      this.store.dispatch(globalLoading(true));
      this.store.dispatch(searchFormById({ id: this.formId, addLoader: true }));

      this.formSnapshot$
        .pipe(
          map(form => form),
          filter((form: FormModel) => !!form.id),
          takeUntil(this.destroy$),
        )
        .subscribe((form: FormModel) => {
          this.headerService.setHeaderText(`${form.name} - ${this.formTypePipe.transform(form.type)}`);
        });
    }
    this.secondLevelLinks = this.secondLevelLinks.filter(
      link => !link.permission || !!this.permissionsService.getPermission(link.permission),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetCurrentForm({ formType: null }));
  }
}
