/* src/app/form/form-preview/phone-template/iphone-15-pro-template/iphone-15-pro-template.component.scss */
.phone {
  display: flex;
}
.phone .body {
  position: relative;
  background: black;
  border-radius: 60px;
  border: 1px #292824 solid;
  box-shadow: 0px 0px 0.31px 1.72px #474641 inset;
}
.phone .body .screen-background {
  background: white;
  box-shadow: 0px 0px 0.34px 5.2px #242223 inset;
  border-radius: 62.5px;
  border: 2px #373634 solid;
}
.phone .body .screen-background .notch {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  width: 61px;
  height: 5px;
  background: #141414;
  box-shadow: 0px 0px 1.8240638971px 0.3648127913px black inset;
  border-radius: 11px;
  border: 0.5px #0E0E0E solid;
}
.phone .body .screen-background .dynamic-island {
  width: 103.21px;
  height: 28.69px;
  position: absolute;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  background: #0a0a0a;
  border-radius: 13.62px;
}
.phone .body .screen-background .dynamic-island-camera {
  width: 15.5px;
  height: 15.5px;
  position: absolute;
  top: 50%;
  left: 81.17px;
  transform: translateY(-50%);
  opacity: 0.7;
  background:
    radial-gradient(
      100% 100%,
      black 21%,
      #081a32 27%,
      #1f3a58 40%,
      #375f90 52%,
      #274871 71%,
      #0d1528 92%),
    conic-gradient(
      from 180deg at 0% 100%,
      rgba(0, 0, 0, 0) 148deg,
      black 184deg,
      rgba(0, 0, 0, 0) 294deg),
    conic-gradient(
      from 180deg at 0% 100%,
      black 0deg,
      rgba(0, 0, 0, 0) 60deg,
      rgba(0, 0, 0, 0) 319deg);
  border-radius: 9999px;
  border: 0.51px #080f21 solid;
}
.phone .body .screen-background .screen-viewport {
  height: 852px;
  width: 393px;
  margin: 5px;
  padding-top: 70px;
}
.phone .body .screen-background .screen-viewport-content {
  height: calc(100% - 16px);
}
.phone .body .screen-background .screen-viewport .navigation-bar {
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.phone .body .screen-background .screen-viewport .navigation-bar-pill {
  width: 72px;
  height: 2px;
  background: rgba(0, 0, 0, 0.54);
  border-radius: 4px;
}
.phone .left-buttons,
.phone .right-buttons {
  display: flex;
  flex-direction: column;
}
.phone .left-buttons *[class$=-button],
.phone .right-buttons *[class$=-button] {
  width: 4.5px;
  flex-shrink: 0;
  border-radius: 1.39px;
  border: 0.521px solid #7F7E79;
  background: #666560;
  box-shadow:
    0px -1.042px 0px 0px #BEBDB8 inset,
    0px 1.042px 0px 0px #BEBDB8 inset,
    0px 2.78px 0.695px 0px #282722 inset,
    0px -2.78px 0.695px 0px #282722 inset,
    1.737px 0px 1.39px 0px rgba(152, 151, 146, 0.6) inset,
    1.39px 0px 0px 0px #92918C inset;
}
.phone .left-buttons .silence-button,
.phone .right-buttons .silence-button {
  height: 37px;
}
.phone .left-buttons .plus-button,
.phone .left-buttons .minus-button,
.phone .right-buttons .plus-button,
.phone .right-buttons .minus-button {
  height: 69px;
}
.phone .left-buttons .plus-button,
.phone .right-buttons .plus-button {
  margin-top: 27px;
  margin-bottom: 14.5px;
}
.phone .left-buttons .power-button,
.phone .right-buttons .power-button {
  height: 105px;
}
.phone .left-buttons {
  margin-top: 169px;
}
.phone .right-buttons {
  margin-top: 256px;
}
/*# sourceMappingURL=iphone-15-pro-template.component.css.map */
