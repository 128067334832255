import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'phar-progress-bar',
  templateUrl: 'progress-bar.component.html',
  styleUrl: 'progress-bar.component.scss',
  standalone: true,
})
export class ProgressBarComponent {
  total = input.required<number>();
  current = input.required<number>();
  progress = computed(() => {
    return this.total() > 0 ? Math.round((this.current() / this.total()) * 100) : 0;
  });
}
