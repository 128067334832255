import {
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
} from '@angular/core';

import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep, findIndex as _findIndex, isArray, isEmpty } from 'lodash-es';
import { Action, Store } from '@ngrx/store';

import { FormModel, FormTypeEnum, IFormPage } from '../form.model';
import {
  CurrentFormManage,
  selectAvailableFormPages,
  selectCurrentFormState,
  selectCurrentFormStateForm,
  selectCurrentFormStateFormPopulatedQuestions,
} from '../store/form.state';
import {
  dispatchedCreateUpdateForm,
  formRemoveQuestion,
  pendingCreateUpdateForm,
  refreshCurrentForm,
  searchFormById,
  updateForm,
  updateFormField,
} from '../store/form.actions';
import { AppState, getState } from '../../store/models/app.state';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { searchDatasetById } from '../../dataset/store/dataset.actions';
import { toggleSidebarSection } from '../../animations';
import { FormButtonsTypesEnum } from './form-buttons-types.enum';
import { UtilsService } from '../../core/utils.service';
import { ElementsEnum, FormElementsEnum } from '../form-elements.enum';
import { PendingChangesControl } from '../../shared/guards/pending-changes.guard';
import { ControlModel } from '../../question/control.model';
import {
  autocreateQuestionSuccess,
  deleteMultipleQuestions,
  resetCurrentQuestion,
  showSaveControlBeforeSavingQuestionDialog,
  softDeleteMultipleQuestions,
  updateQuestion,
} from '../../question/store/question.actions';
import { resetCurrentControl } from '../../store/actions/control.actions';
import { QuestionModel } from '../../question/question.model';
import { Actions, ofType } from '@ngrx/effects';
import { PendingChangesService } from '../../shared/pending-changes.service';
import { QuestionService } from '../../question/question.service';
import { CurrentControlValidationService } from '../../shared/services/current-control-validation.service';
import { DataFieldBindingService } from '../../question/editors/bind-data-field-on-label-change/data-field-binding.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { selectQuestionFeatureCurrentQuestion } from '../../question/store/question.state';
import { BaseComponent } from '../../shared/base.class';
import { ActivatedRoute, Router } from '@angular/router';
import { ResizingEvent } from '../../shared/models/resizing-event.interface';
import { RuleTypeEnum } from '../../rule/rule-type.enum';
import { RuleActionEnum } from '../../rule/rule-action.enum';
import { toSignal } from '@angular/core/rxjs-interop';
import { Statuses } from '../../shared/models/statuses.enum';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsEnum } from '../../permission/permissions.enum';
import { DesktopFormPreviewerComponent } from '../form-preview/desktop-preview/desktop-form-previewer.component';
import { MobileFormPreviewerComponent } from '../form-preview/mobile-preview/mobile-form-previewer.component';
import { ComponentType } from '@angular/cdk/overlay';

enum WidgetEditorType {
  Pages,
  Properties,
  Rules,
}

@Component({
  templateUrl: './form-content.component.html',
  styleUrls: ['./form-content.component.scss'],
  animations: [toggleSidebarSection],
})
export class FormContentComponent extends BaseComponent implements OnInit, OnDestroy, PendingChangesControl {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('formContent', { static: true }) formContent: ElementRef;
  @ViewChild('formContentBody') formContentBody: ElementRef;

  formState$: Observable<CurrentFormManage>;
  currentForm$: Observable<FormModel>;
  currentForm: Signal<FormModel>;
  store: Store<AppState> = inject(Store);
  // its responsible only for rendering the pages, if we need to do some modifications we need to use the currentForm().body.pagescomputed
  availableFormPages: Signal<IFormPage[]> = toSignal(this.store.select(selectAvailableFormPages));
  builderMode: Signal<boolean>;
  allowSelectEditMode: Signal<boolean>;
  editMode = null;
  FormElementsEnum = FormElementsEnum;
  hasPendingChanges$: Observable<boolean>;

  elementsIsOpen = 'open';
  widgetsIsOpen = 'open';
  questionsIsOpen = 'open';

  questionsSelectedIndex = 0;

  pageEvent: PageEvent = {
    pageSize: 1,
    pageIndex: 0,
    length: 1,
  };
  pageSizeOptions = [1];
  pageIdCandidate = null;

  hideLeftAside = false;
  isRightBarFullWidth = false;
  isContentFullWidth = false;

  hideRightAside = false;

  selectedControl$: Observable<ControlModel | null>;
  selectedControlRulesLength$: Observable<number>;
  selectedControl: Signal<ControlModel | null>;
  selectedQuestionSnapshot: Signal<QuestionModel | null>;
  widgetEditorActive = WidgetEditorType.Properties;
  rightBarResizingEvent: ResizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    startingWidth: 0,
  };
  readonly disabledRulesFormElements = [
    FormElementsEnum.QuestionGroup,
    FormElementsEnum.TextBlock,
    FormElementsEnum.TextInput,
    FormElementsEnum.NumericRatingScale,
    FormElementsEnum.VisualAnalogScale,
    FormElementsEnum.TimePicker,
    FormElementsEnum.DatePicker,
    FormElementsEnum.Header,
    FormElementsEnum.Footer,
    FormElementsEnum.Image,
    FormElementsEnum.SingleSelect,
    FormElementsEnum.Number,
  ];
  readonly buttonTypes = FormButtonsTypesEnum;
  readonly formTypes = FormTypeEnum;
  readonly WidgetEditorType = WidgetEditorType;
  private readonly rightBarMinWidth = 270;
  private readonly rightBarMaxWidth = window.innerWidth / 2;
  private readonly injector = inject(Injector);

  constructor(
    private actions$: Actions,
    private confirmDialog: PharConfirmDialogService,
    public dialog: MatDialog,
    public pendingChangesService: PendingChangesService,
    private questionService: QuestionService,
    private utilsService: UtilsService,
    private currentControlValidationService: CurrentControlValidationService,
    private dataFieldBindingService: DataFieldBindingService,
    private route: ActivatedRoute,
    private router: Router,
    private permissionsService: NgxPermissionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formState$ = this.store.select(selectCurrentFormState);
    this.currentForm$ = this.store.select(selectCurrentFormStateForm);
    this.currentForm = toSignal(this.currentForm$, { injector: this.injector });
    // // its responsible only for rendering the pages, if we need to do some modifications we need to use the currentForm().body.pages
    // this.availableFormPages = computed(() => {
    //   return sortBy(this.currentForm().body.pages?.filter(({ isDeleted }) => !isDeleted) || [], 'order', 'asc');
    // });
    this.builderMode = computed(() => {
      const form = this.currentForm();
      return !form.isLocked && !form.isArchived && !!this.permissionsService.getPermission(PermissionsEnum.EditECOA);
    });
    this.allowSelectEditMode = computed(() => {
      const form = this.currentForm();
      return (!form.isLocked || form.formStatus === Statuses.Released) && !form.isArchived;
    });
    this.hasPendingChanges$ = this.pendingChangesService.hasPendingChanges$.pipe(
      filter(() => !this.questionService.isQuestionAutoCreateMode()),
    );
    this.selectedControl$ = this.store
      .select(state => state.control.current.control)
      .pipe(map(control => (control.controlID ? control : null)));
    this.selectedControlRulesLength$ = this.selectedControl$.pipe(
      map((control: ControlModel | null) => {
        let rulesLength = 0;

        if (!control || isEmpty(control.dependencies)) {
          return rulesLength;
        }

        Object.keys(control.dependencies).forEach((ruleType: RuleTypeEnum) => {
          if (isArray(control.dependencies[ruleType])) {
            rulesLength += control.dependencies[ruleType].length;
            return;
          }

          Object.keys(control.dependencies[ruleType]).forEach((ruleAction: RuleActionEnum) => {
            if (isArray(control.dependencies[ruleType][ruleAction].data)) {
              rulesLength += control.dependencies[ruleType][ruleAction].data.length;
            }
          });
        });

        return rulesLength;
      }),
    );

    this.selectedControl = toSignal(this.selectedControl$, { injector: this.injector });
    this.selectedQuestionSnapshot = toSignal(
      this.store.select(selectQuestionFeatureCurrentQuestion).pipe(
        map(state => state.questionSnapshot),
        distinctUntilChanged(),
      ),
      { injector: this.injector },
    );

    let datasetLoaded = false;
    this.currentForm$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(form => {
      if (this.pageIdCandidate) {
        this.navigateToCertainPage(this.pageIdCandidate);
      }
      if (form.datasetId && !datasetLoaded) {
        datasetLoaded = true;
        this.store.dispatch(searchDatasetById({ id: form.datasetId }));
      }
    });

    this.handleSelectedTab();
    this.handleSaveFormOnAutocreateQuestion();
    this.exitFullscreenContentOnSelectControl();
    this.store.dispatch(resetCurrentControl());
    this.store.dispatch(resetCurrentQuestion());

    this.actions$
      .pipe(
        ofType<ReturnType<typeof showSaveControlBeforeSavingQuestionDialog>>(showSaveControlBeforeSavingQuestionDialog),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.displaySaveControlFirstModal();
      });
    this.checkQueryParams();

    this.pendingChangesService.discardChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.discardChanges();
    });
  }

  ngOnDestroy(): void {
    if (getState(this.store).form.current.pendingChanges) {
      this.store.dispatch(refreshCurrentForm());
    }
    super.ngOnDestroy();
  }

  saveAndCheckPendingChanges(): Observable<boolean> {
    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return of(true);
    }

    this.pendingChangesService.saveChanges();

    return this.pendingChangesService.hasPendingChanges$.pipe(filter(hasChanges => !hasChanges));
  }

  saveForm(): void {
    this.utilsService.dispatchActions(this.store, [
      pendingCreateUpdateForm({ pending: true }),
      dispatchedCreateUpdateForm({ dispatched: true }),
      updateForm({ form: this.currentForm() }),
    ]);

    this.formState$
      .pipe(
        filter(({ dispatched, success }) => dispatched && success),
        take(1),
      )
      .subscribe(() => {
        this.utilsService.dispatchActions(this.store, [
          pendingCreateUpdateForm({ pending: false }),
          dispatchedCreateUpdateForm({ dispatched: false }),
        ]);
      });
  }

  editHeaderFooter(type: FormElementsEnum.Header | FormElementsEnum.Footer): void {
    if (this.editMode === type) {
      return;
    }
    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    this.resetCurrentControlAndQuestion();
    this.updateEditMode(type);
  }

  removeHeaderFooter(type: FormElementsEnum.Header | FormElementsEnum.Footer): void {
    this.confirmDialog
      .openConfirmDialog(`Do you want to remove ${type.toLowerCase()}?`)
      .pipe(
        take(1),
        filter(isConfirmed => isConfirmed),
        withLatestFrom(this.store.select(selectCurrentFormStateForm)),
      )
      .subscribe({
        next: ([, form]) => {
          const formSettings = cloneDeep(form.settings);
          const formBody = cloneDeep(form.body);

          if (type === FormElementsEnum.Header) {
            formSettings.showHeader = false;
            formBody.header = { text: '', isSiteLevel: false };
          }
          if (type === FormElementsEnum.Footer) {
            formSettings.showFooter = false;
            formBody.footer = { text: '', isSiteLevel: false };
          }

          this.store.dispatch(
            updateForm({
              form: {
                ...form,
                settings: formSettings,
                body: formBody,
              },
            }),
          );
          this.updateEditMode();
        },
      });
  }

  addPage(beforeCurrent = false): void {
    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }
    this.resetRightPanel();
    // get the currentPageIndex from current rendered pages
    const page: IFormPage = this.availableFormPages()[this.pageEvent.pageIndex];
    let newPageOrder = page.order;
    let newPageEventIndex = this.pageEvent.pageIndex;
    if (!beforeCurrent) {
      newPageOrder = page.order + 1;
      newPageEventIndex = this.pageEvent.pageIndex + 1;
    }

    const newPage: IFormPage = this.createEmptyPage(newPageOrder);

    let updatedPages = cloneDeep(this.availableFormPages()).map((page: IFormPage) => {
      // if the order is lower that the current new page order then stays the same, otherwise we need to increase the order by 1
      if (page.order < newPage.order) {
        return page;
      }
      return {
        ...page,
        order: page.order + 1,
      };
    });

    //first update questions and controls
    //passing a newPageEventIndex as a second argument to reindex all controls after/before the new page
    const updatedQuestions = this.getQuestionsWithReindexControls(updatedPages, newPageEventIndex);
    // merge new page with updated pages
    updatedPages = [...updatedPages, newPage];
    // merge new page with updated pages
    //
    this.store.dispatch(
      updateFormField({
        field: 'body',
        value: { ...this.currentForm().body, pages: updatedPages },
      }),
    );
    this.resetCurrentControlAndQuestion();

    if (updatedQuestions.length) {
      this.questionService
        .updateMultiple(updatedQuestions, this.currentForm())
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.saveForm();
            this.pageEvent.pageIndex = newPageEventIndex;
          },
        });
    } else {
      this.saveForm();
      this.pageEvent.pageIndex = newPageEventIndex;
    }
  }

  removePage(): void {
    const page: IFormPage = this.availableFormPages()[this.pageEvent.pageIndex];
    const pageIndex = this.availableFormPages().findIndex(({ page_uuid }) => page_uuid === page.page_uuid);
    const childQuestions = page.questions;
    const confirmMessage = childQuestions.length
      ? 'This page contains questions and/or other components. Do you want to remove the page and the components'
      : 'Do you want to remove this page?';

    this.confirmDialog
      .openConfirmDialog(confirmMessage)
      .pipe(
        take(1),
        filter(isConfirmed => isConfirmed),
        withLatestFrom(this.store.select(selectCurrentFormStateFormPopulatedQuestions)),
        switchMap(([, questionsPopulated]) => {
          if (this.currentForm().isAmended) {
            if (childQuestions.length) {
              const questionsForHardDeleted = [];
              const questionsForSoftDelete = [];

              childQuestions.forEach((q: { id: number }) => {
                const question: QuestionModel = questionsPopulated[q.id];
                if (!question) {
                  return;
                }
                if (question.isAmended) {
                  questionsForSoftDelete.push(q);
                } else {
                  questionsForHardDeleted.push(q);
                }
              });
              if (questionsForHardDeleted.length) {
                this.hardDeletePageQuestions(questionsForHardDeleted, questionsPopulated, page);
              }
              if (questionsForSoftDelete.length) {
                // update all questions and their controls with isDeleted true
                this.store.dispatch(softDeleteMultipleQuestions({ ids: questionsForSoftDelete.map(q => q.id) }));
              }
            }

            this.currentForm().body.pages = this.syncPages(this.currentForm().body.pages, page);
            this.updateFormBody();

            // remove questions from questions and questionPopulated fields;
            childQuestions.forEach((q: QuestionModel) => {
              this.dispatchFormRemoveQuestion(q, false);
            });
          } else {
            this.hardDeletePageQuestions(childQuestions, questionsPopulated, page);
          }

          this.resetCurrentControlAndQuestion();
          this.paginator.previousPage();

          const updatedQuestions = this.getQuestionsWithReindexControls(this.currentForm().body.pages, pageIndex);

          return updatedQuestions.length
            ? this.questionService.updateMultiple(updatedQuestions, this.currentForm())
            : of(null);
        }),
      )
      .subscribe(() => {
        this.saveForm();
      });
  }

  syncBodyPagesOrder(pages: IFormPage[], currentPage: IFormPage): IFormPage[] {
    return pages.map((pageItem: IFormPage) => {
      // if the order is lower that the current new page order then stays the same, otherwise we need to increase the order by 1
      if (pageItem.order < currentPage.order) {
        return pageItem;
      }
      let newOrder = pageItem.order - 1;
      if (newOrder <= 0) {
        newOrder = 1;
      }
      return {
        ...pageItem,
        order: newOrder,
      };
    });
  }

  updatePage(event: { type: any; data: IFormPage; element: any }): void {
    const index = _findIndex(this.currentForm().body.pages, page => page.pageID === event.data.pageID);
    this.currentForm().body.pages[index] = event.data;
    if (event.type === 'removeElement' && event.element.controlType !== ElementsEnum.Textblock) {
      const selectedQuestion: QuestionModel = this.currentForm().questionsPopulated[event.element.id];
      if (selectedQuestion.isAmended && this.currentForm().isAmended) {
        //use soft delete
        // update questions and controls with isDeleted: true
        this.store.dispatch(softDeleteMultipleQuestions({ ids: [event.element.id] }));
      } else {
        //hard delete
        this.removeQuestionControlsDataFields(event.element.id);
        this.store.dispatch(deleteMultipleQuestions({ ids: [event.element.id] }));
      }
      this.dispatchFormRemoveQuestion(event.element, false);
      this.resetCurrentControlAndQuestion();
      this.resetRightPanel();
    }
    this.updateFormBody(event.type === 'dropElement', event.type === 'removeElement');
  }

  getQuestionsWithReindexControls(pages: any[], startPageIndex: number): QuestionModel[] {
    const currenFormQuestionsPopulated = cloneDeep(this.currentForm().questionsPopulated);
    //pages without pages that we are about to delete
    const pageIndexes: number[] = pages.map((_, index) => index).slice(startPageIndex);

    const reduced: QuestionModel[] = pageIndexes.reduce((questions: QuestionModel[], pageIndex: number) => {
      const page = pages[pageIndex];
      const pageQuestionIds: number[] = pages[pageIndex].questions.map(({ id }) => id);
      const pageQuestions: QuestionModel[] = pageQuestionIds
        .map(questionId => {
          return currenFormQuestionsPopulated[questionId];
        })
        .filter(question => !!question);
      const updatedPageQuestions: QuestionModel[] = pageQuestions.map(question => {
        const controls: ControlModel[] = question.controls;
        const updatedControls: ControlModel[] = controls.map(control => {
          return { ...control, pageIndex: page.order } as ControlModel;
        });

        return { ...question, pageIndex: page.order, controls: updatedControls };
      });

      return [...questions, ...updatedPageQuestions];
    }, []);

    return reduced;
  }

  dispatchFormRemoveQuestion(question, save = true): void {
    // code will stay commented until we ensure that everything is working well
    // const dispatch = !!this.currentForm().body.pages.find(
    //   page => page.questions.length && page.questions.find(q => q.id === question.id),
    // );

    // if (dispatch) {
    this.store.dispatch(formRemoveQuestion({ questionId: question.id }));
    if (save) {
      this.saveAndCheckPendingChanges();
    }
    // }
  }

  removeQuestionControlsDataFields(questionId: string): void {
    const question: QuestionModel = getState(this.store).form.current.form.questionsPopulated[questionId];
    if (!question) {
      return;
    }

    question.controls.forEach(control => {
      this.dataFieldBindingService.removeControlDataFields(control);
    });
    this.dataFieldBindingService.saveDataFields();
  }

  // afterDeleteQuestion(id): void {
  //   this.store.dispatch(formRemoveQuestion({ questionId: id }));
  // }
  //
  // afterUpdateQuestion(question): void {
  //   this.store.dispatch(formUpdateQuestion({ question }));
  // }

  checkPendingChangesOnClick() {
    setTimeout(() => {
      if (this.pendingChangesService.hasPendingChanges) {
        this.pendingChangesService.openPendingChangesSaveDialog();
      }
    }, 150);
  }

  discardChanges() {
    this.store.dispatch(resetCurrentControl());
    this.store.dispatch(resetCurrentQuestion());
    this.store.dispatch(refreshCurrentForm());
    this.widgetEditorActive = WidgetEditorType.Properties;
    this.updateEditMode();
    this.store.dispatch(searchFormById({ id: this.currentForm().id }));
  }

  saveCurrentQuestionPendingChanges() {
    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }

    const currentQuestion = getState(this.store).question.current.question;
    this.store.dispatch(updateQuestion({ question: currentQuestion }));
    this.dataFieldBindingService.saveDataFields();
  }

  setActiveTabIndex(widgetEditorType: WidgetEditorType) {
    // Prevent opening rules if we have invalid control inputs.
    if (widgetEditorType === WidgetEditorType.Rules && !this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }

    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    if (this.questionService.isQuestionAutoCreateMode()) {
      return;
    }

    this.widgetEditorActive = widgetEditorType;
    if (widgetEditorType === WidgetEditorType.Pages) {
      this.store.dispatch(resetCurrentControl());
      this.store.dispatch(resetCurrentQuestion());
    }
  }

  toggleContentFullWidth(): void {
    this.isContentFullWidth = !this.isContentFullWidth;
  }

  toggleRightBarFullWidth(): void {
    this.isRightBarFullWidth = !this.isRightBarFullWidth;
    window.dispatchEvent(new Event('resize'));
  }

  toggleWidgetsPanel(): void {
    this.widgetsIsOpen = this.widgetsIsOpen === 'open' ? 'close' : 'open';
  }

  toggleQuestionsPanel(): void {
    this.questionsIsOpen = this.questionsIsOpen === 'open' ? 'close' : 'open';
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.currentForm().body.pages.filter(({ isDeleted }) => !isDeleted)[this.pageEvent.pageIndex].buttons,
      event.previousIndex,
      event.currentIndex,
    );
    this.updateFormBody();
  }

  updateFormBody(updatePageIndexes = false, shouldUpdateForm = false): void {
    const value = {
      ...this.currentForm().body,
      pages: this.currentForm().body.pages,
    };
    if (updatePageIndexes) {
      value.pages = value.pages.map(page => {
        return {
          ...page,
          questions: page.questions.map((question, index) => {
            return { ...question, order: index };
          }),
        };
      });
    }

    this.store.dispatch(
      updateFormField({
        field: 'body',
        value,
      }),
    );
    if (shouldUpdateForm) {
      this.store.dispatch(updateForm({ form: getState(this.store).form.current.form }));
    }
  }

  updateEditMode(editMode?: FormElementsEnum) {
    const propertiesEditorModes = [FormElementsEnum.QuestionGroup, FormElementsEnum.Header, FormElementsEnum.Footer];

    this.editMode = editMode;

    if (propertiesEditorModes.includes(this.editMode)) {
      this.widgetEditorActive = WidgetEditorType.Properties;
    }
  }

  onPageChange(event: PageEvent): void {
    if (this.pendingChangesService.hasPendingChanges) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      this.paginator.pageIndex = this.pageEvent.pageIndex;
      return;
    }
    this.pageEvent = event;
    this.resetCurrentControlAndQuestion();
    this.resetRightPanel();
    this.scrollToTop();
  }

  resetCurrentControlAndQuestion(): void {
    const actions: Action[] = [resetCurrentControl(), resetCurrentQuestion()];

    this.utilsService.dispatchActions(this.store, actions);
  }

  startRightBarResizing(event: MouseEvent): void {
    this.rightBarResizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.getRightBarWidth(),
    };
  }

  openFormPreviewer(): void {
    const isMobilePreview = this.currentForm().type === FormTypeEnum.DataCapture;
    const previewerComponent: ComponentType<MobileFormPreviewerComponent | DesktopFormPreviewerComponent> =
      isMobilePreview ? MobileFormPreviewerComponent : DesktopFormPreviewerComponent;

    const sizes = isMobilePreview
      ? {
          maxWidth: '95vw',
          maxHeight: '95vh',
        }
      : {
          minWidth: '95vw',
          height: '95vh',
        };

    this.dialog.open(previewerComponent, {
      data: {
        form: this.currentForm(),
      },
      autoFocus: false,
      ...sizes,
    });
  }

  scrollToTop(): void {
    const formContentNative = this.formContentBody.nativeElement;

    if (formContentNative.clientHeight < formContentNative.scrollHeight) {
      formContentNative.scrollTop = 0;
    }
  }

  handleRequestPageChange(pageId: string): void {
    this.navigateToCertainPage(pageId);
  }

  handleRequestEditQuestion(question: QuestionModel): void {
    const controls: ControlModel[] | undefined = question?.controls.filter(c => !c.isDeleted);
    if (!controls?.length) {
      return;
    }

    this.utilsService.waitForElementToExist(`#ctrl-${controls[0].controlID}`, 9999).then((el: HTMLElement) => {
      el.click();
    });
  }

  handleRequestRightPanel(): void {
    this.resetRightPanel();
  }

  private hardDeletePageQuestions(
    childQuestions: QuestionModel[],
    questionsPopulated: Record<string, QuestionModel>,
    page: IFormPage,
  ): void {
    if (childQuestions.length) {
      this.store.dispatch(deleteMultipleQuestions({ ids: childQuestions.map(q => q.id) }));
    }

    const pageControls: ControlModel[] = [].concat(...childQuestions.map(q => questionsPopulated[q.id].controls));
    this.dataFieldBindingService.removeControlsDataFields(pageControls);
    this.dataFieldBindingService.saveDataFields();

    childQuestions.forEach((q: QuestionModel) => {
      this.dispatchFormRemoveQuestion(q, false);
    });

    this.currentForm().body.pages = this.syncPages(this.currentForm().body.pages, page);
    this.updateFormBody();
  }

  private syncPages(pages: IFormPage[], page: IFormPage): IFormPage[] {
    //decide to hard delete or soft delete ( isDeleted: true )
    pages = this.deleteOrUpdatePage(pages, page);
    //update order to be in sync
    pages = this.syncBodyPagesOrder(pages, page);

    return pages;
  }

  @HostListener('window:mousemove', ['$event'])
  private updateSidebarWidth(event: MouseEvent) {
    if (!this.rightBarResizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = this.rightBarResizingEvent.startingCursorX - event.clientX;
    const newWidth = this.rightBarResizingEvent.startingWidth + cursorDeltaX;

    this.setRightBarWidth(newWidth);
  }

  @HostListener('window:mouseup')
  private stopSidebarResizing() {
    this.rightBarResizingEvent.isResizing = false;
  }

  private getRightBarWidth(): number {
    return parseInt(getComputedStyle(this.formContent.nativeElement).getPropertyValue('--rightBarWidth'), 10);
  }

  private setRightBarWidth(width: number) {
    const clampedWidth = Math.min(Math.max(width, this.rightBarMinWidth), this.rightBarMaxWidth);

    this.formContent.nativeElement.style.setProperty('--rightBarWidth', `${clampedWidth}px`);
    window.dispatchEvent(new Event('resize'));
  }

  private exitFullscreenContentOnSelectControl(): void {
    this.selectedControl$
      .pipe(
        map(control => control?.controlID),
        distinctUntilChanged(),
        filter(controlId => !!controlId),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.isContentFullWidth = false;
      });
  }

  private handleSaveFormOnAutocreateQuestion() {
    this.actions$
      .pipe(ofType<ReturnType<typeof autocreateQuestionSuccess>>(autocreateQuestionSuccess), takeUntil(this.destroy$))
      .subscribe(() => {
        this.saveForm();
      });
  }

  private handleSelectedTab() {
    this.selectedControl$
      .pipe(
        map(control => control?.controlID),
        distinctUntilChanged(),
        filter(controlId => !!controlId),
        filter(() => this.widgetEditorActive !== WidgetEditorType.Properties),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.widgetEditorActive = WidgetEditorType.Properties;
      });
  }

  private displaySaveControlFirstModal() {
    this.confirmDialog
      .openConfirmDialog('Please save control before saving question?', '', 'Close', '')
      .pipe(take(1))
      .subscribe();
  }

  private createEmptyPage(order: number): IFormPage {
    return {
      title: `Page-${new Date().getTime()}`,
      pageID: `P-${new Date().getTime()}`,
      page_uuid: this.utilsService.generateUUID(),
      questions: [],
      buttons: [],
      isDeleted: false,
      isAmended: false,
      order,
    };
  }

  private resetRightPanel() {
    this.widgetEditorActive = WidgetEditorType.Properties;
    this.updateEditMode(null);
  }

  private checkQueryParams(): void {
    if (this.route.snapshot.queryParams['pageId']) {
      const pageId: string = this.route.snapshot.queryParams['pageId'];
      this.clearQueryParams();
      if (!pageId) {
        return;
      }

      if (!this.currentForm().id) {
        this.pageIdCandidate = pageId;
        return;
      }

      this.navigateToCertainPage(pageId);
    }
  }

  private navigateToCertainPage(pageId: string): void {
    const pageIndex = this.availableFormPages().findIndex(page => page.pageID === pageId);
    if (pageIndex < 0) {
      return;
    }
    this.pageEvent.pageIndex = pageIndex;
    //clear the pageIdCandidate
    this.pageIdCandidate = null;
  }

  private clearQueryParams(): void {
    this.router.navigate([], {
      queryParams: {
        pageId: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  private deleteOrUpdatePage(pages: IFormPage[], page: IFormPage): IFormPage[] {
    if (page.isAmended) {
      // soft delete the page -> set isDeleted to true
      return pages.map((p: IFormPage) => {
        if (p.page_uuid === page.page_uuid) {
          return { ...page, isDeleted: true };
        }
        return p;
      });
    } else {
      // hard delete the page if it isn`t amended
      return pages.filter(p => p.page_uuid !== page.page_uuid);
    }
  }
}
