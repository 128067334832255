import { Component, inject, Inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormModel } from '../../form.model';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormPreviewContentComponent } from '../form-preview-content/form-preview-content.component';
import { FormPreviewDialogTemplateComponent } from '../form-preview-dialog-template/form-preview-dialog-template.component';
import { FormPreviewSidebarComponent } from '../form-preview-sidebar/form-preview-sidebar.component';
import { ISidebarGroup } from '../form-preview-sidebar/sidebar-group.interface';
import { ButtonComponent } from '../../../shared/phar-button/phar-button.component';
import { MatIcon } from '@angular/material/icon';
import { FormPreviewPaginationService } from '../form-preview-pagination.service';

@Component({
  templateUrl: './desktop-form-previewer.component.html',
  styleUrl: './desktop-form-previewer.component.scss',
  standalone: true,
  imports: [
    NgxPermissionsModule,
    FormPreviewContentComponent,
    FormPreviewDialogTemplateComponent,
    FormPreviewSidebarComponent,
    ButtonComponent,
    MatIcon,
  ],
})
export class DesktopFormPreviewerComponent {
  activeSidebarItem = signal('Chrome');
  formPreviewService = inject(FormPreviewPaginationService);
  sidebarItems: ISidebarGroup[] = [{ name: 'Browser', items: [{ name: 'Chrome' }, { name: 'Mozilla Firefox' }] }];
  constructor(
    private dialogRef: MatDialogRef<DesktopFormPreviewerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      form: FormModel;
    },
  ) {
    this.formPreviewService.form.set(this.data.form);
  }

  close(): void {
    this.dialogRef.close();
  }

  updateActiveItem(item: string): void {
    this.activeSidebarItem.set(item);
  }
}
